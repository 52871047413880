<template>
    <div class="gradient-background">
        <div class="statistics-page">
            <h1 class="gradient-text">网站访问统计</h1>
            <div class="statistics-cards">
                <div class="statistics-card" v-for="(stat, index) in statistics" :key="index">
                    <h2>{{ stat.title }}</h2>
                    <p>{{ stat.value }}</p>
                </div>
            </div>
            <div class="statistics-table">
                <table>
                    <thead>
                    <tr>
                        <th>日期</th>
                        <th>访问量</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(record, index) in detailedStatistics" :key="index">
                        <td>{{ record.date }}</td>
                        <td>{{ record.visits }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="floating-buttons">
            <button @click="goBack">返回上一页</button>
            <button @click="goHome">返回首页</button>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'

// 从 .env 文件中获取 API 基础 URL
const apiBaseUrl = process.env.VUE_APP_API_BASE_URL

const router = useRouter()
const statistics = ref([])
const detailedStatistics = ref([])

const fetchStatistics = async () => {
    try {
        const response = await axios.get(`${apiBaseUrl}/getStatistics`)
        if (response.data.code === '200') {
            statistics.value = response.data.data.summary
            detailedStatistics.value = response.data.data.details
        }
    } catch (error) {
        console.error('获取统计数据时出错：', error)
    }
}

const goBack = () => {
    router.back()
}

const goHome = () => {
    router.push('/')
}

onMounted(() => {
    fetchStatistics()
})
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.gradient-background {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    position: relative;
    background: linear-gradient(135deg, #ffccff, #ccffff, #cc99ff);
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.statistics-page {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    width: 100%;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.8);
}

.gradient-text {
    margin: 0;
    font-size: 24px;
    background: linear-gradient(135deg, #000000, #ff0000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.statistics-cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
}

.statistics-card {
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 200px;
    text-align: center;
}

.statistics-card h2 {
    margin: 0;
    font-size: 18px;
    color: #333;
}

.statistics-card p {
    margin: 10px 0 0;
    font-size: 24px;
    font-weight: bold;
    color: #007bff;
}

.statistics-table {
    margin-top: 30px;
    overflow-x: auto;
}

.statistics-table table {
    width: 100%;
    border-collapse: collapse;
}

.statistics-table th, .statistics-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
}

.statistics-table th {
    background-color: #f4f4f4;
}

.floating-buttons {
    position: fixed;
    bottom: 50px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 9999;
}

.floating-buttons button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.floating-buttons button:hover {
    background-color: #0056b3;
}
</style>
