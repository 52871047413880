import { createRouter, createWebHistory } from 'vue-router'
import SearchPage from '../components/SearchPage.vue'
import SearchResults from '../components/SearchResults.vue'
import DetailPage from '../components/DetailPage.vue'
import CountPage from '../components/CountPage.vue'

const routes = [
    { path: '/', component: SearchPage, meta: { title: '大佬TV_全网影视在线搜索' }},
    { path: '/results', name: 'results', component: SearchResults, meta: { title: '在线搜索' }},
    { path: '/detail/:id/:api_identifier', name: 'detail', component: DetailPage, meta: { title: '在线播放' }},
    { path: '/count', name: 'count', component: CountPage, meta: { title: '站点统计' }},
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
