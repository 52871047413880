<template>
    <div class="search-container">
        <div class="search-box">
            <div class="soico"></div>
            <form @submit.prevent="search" class="search-form">
                <div class="search-input-wrapper">
                    <input v-model="query.wd" type="text" placeholder="请输入电影名称..." class="search-input"/>
                    <span @click="search" class="search-icon"></span>
                </div>
            </form>
            <!-- 添加热门搜索 -->
            <div class="hot-search">
                <label class="hot-search-label">热门搜索</label>
                <div class="hot-search-buttons">
                    <button v-for="(item, index) in displayedHotSearches" :key="index" @click="setHotSearch(item)">
                        {{ item }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';

const query = ref({ wd: '' });
const router = useRouter();

// 热门搜索关键词
const hotSearches = ref(['庆余年', '权力的游戏', '速度与激情', '星球大战']);

// 移动端宽度阈值
const mobileWidth = 600;
const isMobile = ref(window.innerWidth <= mobileWidth);

// 检测窗口大小变化
function updateIsMobile() {
    isMobile.value = window.innerWidth <= mobileWidth;
}

// 监听窗口大小变化
onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
    updateIsMobile(); // 初始检查
});

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
});

// 根据设备类型显示的热门搜索
const displayedHotSearches = computed(() => {
    return isMobile.value ? hotSearches.value.slice(0, 3) : hotSearches.value;
});

// 搜索方法
function search() {
    router.push({ name: 'results', query: { wd: query.value.wd } });
}

// 设置热门搜索
function setHotSearch(item) {
    query.value.wd = item;
    search();
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.search-box {
    text-align: center;
    font-family: 'Roboto', sans-serif;
}

.search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #ffccff, #ccffff, #cc99ff);
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.soico {
    background: url('https://qiniu.htoo.vip/img/VRlogo.svg') no-repeat;
    width: 150px;
    height: 140px;
    margin: 0 auto 1rem auto;
    background-size: cover;
    background-position: 0 -10px;
}

.search-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.search-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.search-input {
    padding: 0.75rem 2.5rem 0.75rem 1rem;
    font-size: 1.2rem;
    border: 1px solid #ccc;
    border-radius: 25px; /* 圆角边框 */
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0); /* 半透明背景 */
}

.search-input:focus {
    border-color: #0088cc;
    outline: none;
}

.search-icon {
    position: absolute;
    right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    background: url('https://img.icons8.com/ios-filled/50/0088cc/search.png') no-repeat center/contain;
    cursor: pointer;
}

/* 热门搜索样式 */
.hot-search {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.hot-search-label {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
}

.hot-search-buttons {
    display: flex;
    flex-wrap: wrap; /* 允许换行 */
    gap: 0.5rem;
    justify-content: center;
}

.hot-search button {
    padding: 0.5rem 1rem;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-size: 0.9rem;
    flex: 1 1 auto; /* 使按钮在移动端自适应 */
    max-width: 120px; /* 限制按钮的最大宽度 */
}

.hot-search button:hover {
    background-color: #0056b3;
}

@media (max-width: 600px) {
    .hot-search button {
        font-size: 0.8rem; /* 调整移动端的按钮字体大小 */
        padding: 0.4rem 0.8rem; /* 调整移动端的按钮内边距 */
    }
}
</style>
