<template>
    <div id="app">
        <router-view/>
        <div class="floating-disclaimer">
            本程序仅开发测试使用，不提供、不存储任何服务内容。
        </div>
    </div>
</template>

<style>
body {
    margin: 0;
}

/* 全局样式 */
#app {
    position: relative;
    min-height: 100vh;
}

/* 底部悬浮块样式 */
.floating-disclaimer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.6); /* 半透明黑色背景 */
    color: #ffffff; /* 白色文字 */
    text-align: center;
    font-size: 14px;
    z-index: 9998; /* 确保在悬浮按钮之下 */
}

/* 为移动端调整样式 */
@media (max-width: 600px) {
    .floating-disclaimer {
        font-size: 12px; /* 调整字体大小 */
        padding: 8px; /* 调整内边距 */
    }
}
</style>
