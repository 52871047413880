<template>
    <div class="gradient-background">
        <div class="results-page">
            <div v-if="loading" class="loading-spinner-container">
                <div class="loading-spinner"></div>
            </div>
            <div v-else>
                <div v-if="error" class="error-message">系统异常，请稍后再试。</div>
                <div v-else-if="results.length === 0" class="no-data-message">暂无数据</div>
                <div class="card-container" v-else>
                    <div class="card" v-for="(row, index) in results" :key="index">
                        <div class="card-content">
                            <div class="card-details">
                                <div class="name-and-tag">
                                    <h3 class="gradient-text">{{ row.vod_name }}</h3>
                                    <el-tag class="gradient-tag">{{ row.api_identifier }}</el-tag>
                                </div>
                                <span class="tag-text">{{ row.type_name }} <span class="vod-time">| {{
                                        row.vod_time
                                    }}</span> | {{ row.vod_remarks }}</span>
                            </div>
                            <router-link
                                :to="{ name: 'detail', params: { id: row.vod_id, api_identifier: row.api_identifier } }"
                                class="icon-link">
                                <el-icon class="gradient-icon">
                                    <VideoPlay/>
                                </el-icon>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="floating-buttons"
            @mousedown="startDrag"
            @mousemove="drag"
            @mouseup="endDrag"
            @mouseleave="endDrag"
        >
            <button @click="goHome">返回首页</button>
        </div>
    </div>
</template>

<script setup>
import {ref, onMounted, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import axios from 'axios'
import {ElIcon, ElTag} from 'element-plus'
import {VideoPlay} from '@element-plus/icons-vue'

// 从 .env 文件中获取 API 基础 URL
const apiBaseUrl = process.env.VUE_APP_API_BASE_URL

const route = useRoute()
const router = useRouter()
const results = ref([])
const loading = ref(false)
const error = ref(false)
const isDragging = ref(false)
const startX = ref(0)
const startY = ref(0)
const offsetX = ref(0)
const offsetY = ref(0)

const goHome = () => {
    router.push('/')
}

const startDrag = (event) => {
    isDragging.value = true
    startX.value = event.clientX
    startY.value = event.clientY
    offsetX.value = event.target.parentElement.offsetLeft
    offsetY.value = event.target.parentElement.offsetTop
}

const drag = (event) => {
    if (isDragging.value) {
        const dx = event.clientX - startX.value
        const dy = event.clientY - startY.value
        const button = event.target.parentElement
        button.style.left = `${offsetX.value + dx}px`
        button.style.top = `${offsetY.value + dy}px`
    }
}

const endDrag = () => {
    isDragging.value = false
}

const fetchResults = async () => {
    const {wd} = route.query
    loading.value = true
    error.value = false

    try {
        // 使用新的 API 接口
        const response = await axios.get(`${apiBaseUrl}/getList`, {
            params: {
                name: wd || '', // 默认搜索 "飞驰人生2"
            }
        })

        if (response.data.code === '200') {
            results.value = response.data.data || []
        } else {
            console.error('获取数据时出错：', response.data.message)
            error.value = true
        }
    } catch (error) {
        console.error('网络错误：', error)
        error.value = true
    } finally {
        setTimeout(() => {
            loading.value = false
        }, 500) // 添加一个轻微的延迟以实现更平滑的过渡
    }
}

// 监听路由变化
watch(route, fetchResults)

// 初始加载
onMounted(fetchResults)
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.floating-buttons {
    position: fixed;
    bottom: 80px; /* 避开免责声明 */
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 9999; /* 确保在最前面 */
    cursor: move; /* 显示拖动图标 */
}

.floating-buttons button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.floating-buttons button:hover {
    background-color: #0056b3;
}

.gradient-background {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #ffccff, #ccffff, #cc99ff);
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.results-page {
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
    max-width: 900px;
    width: 100%;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
    position: relative; /* 为了让进度条能覆盖整个容器 */
    padding-bottom: 50px;
}

.loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #007bff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.no-data-message, .error-message {
    font-size: 18px;
    color: #ff0000;
    margin-top: 20px;
}

.card-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.card {
    background: rgba(0, 188, 212, 0);
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}

.card-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.card-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden; /* 防止文字溢出 */
    max-width: 70%; /* 限制最大宽度 */
}

.name-and-tag {
    display: flex;
    align-items: center;
    gap: 10px; /* 间距可根据需要调整 */
}

.gradient-text {
    margin: 0;
    font-size: 16px;
    background: linear-gradient(135deg, #000000, #ff0000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.gradient-tag {
    font-size: 12px;
    background: linear-gradient(135deg, #030303, #ff0000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%; /* 限制最大宽度 */
}

.tag-text {
    font-size: 12px;
    background: linear-gradient(135deg, #000000, #ff0000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.icon-link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px; /* 增加图标的大小 */
    color: #00d463;
    text-decoration: none;
    min-width: 40px; /* 确保按钮宽度 */
    min-height: 40px; /* 确保按钮高度 */
}

.icon-link:hover {
    color: #9e0000;
}

/* 移动端样式 */
@media (max-width: 768px) {
    .gradient-background {
        align-items: flex-start;
    }

    .results-page {
        padding: 10px;
        border-radius: 0;
        box-shadow: none;
        min-height: 100vh;
    }

    .card {
        flex-direction: row; /* 保持图标在右侧 */
        justify-content: space-between; /* 保持左右对齐 */
        align-items: center;
    }

    .card-content {
        flex-direction: row; /* 保持图标在右侧 */
        justify-content: space-between; /* 保持左右对齐 */
        align-items: center;
        width: 100%;
    }

    .card-details {
        max-width: 60%; /* 移动端限制最大宽度 */
    }

    .vod-time {
        display: none; /* 在移动端隐藏更新时间 */
    }
}
</style>
